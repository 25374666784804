<template>
  <div v-if="currentUser.superAdmin">
    <div class="w-300px pl-4 pb-4">
      <v-combobox
        :items="countries"
        :label="$t('commonTable.country')"
        item-text="name"
        item-value="id"
        v-model="selectedCountry"
        @change="onChangeCountry"
        outline
      ></v-combobox>
    </div>
    <v-data-table
      :headers="headers"
      :items="bannerInstances"
      :options.sync="pagination"
      :server-items-length="totalBannerInstances"
      :loading="loading"
      :footer-props="{
        showFirstLastPage: true,
        'items-per-page-options': [10, 20, 50, 100]
      }"
      class="elevation-1"
    >
      <template v-slot:item.start="{ item }">
        {{ parseDate(item.start) }}
      </template>
      <template v-slot:item.end="{ item }">
        {{ parseDate(item.end) }}
      </template>
      <template v-slot:item.city="{ item }">
        {{ item.city != null ? item.city.name : "" }}
      </template>
      <template v-slot:item.country="{ item }">
        {{ item.country.name != null ? item.country.name : "" }}
      </template>
      <template v-slot:item.bannerName="{ item }">
        <td class="text-xs-right">
          {{ item.oobleeBannerDefinition != null ? item.oobleeBannerDefinition.text : "" }}
        </td>
      </template>
      <template v-slot:item.allCities="{ item }">
        <v-switch v-model="item.allCities" disabled></v-switch>
      </template>
      <template v-slot:item.active="{ item }">
        <v-switch
          v-model="item.active"
          @click="changeBannerStatus(item.id, item.active)"
        ></v-switch>
      </template>
      <template v-slot:item.clear="{ item }">
        <v-avatar
          v-if="currentUser.superAdmin || (!currentUser.superAdmin && !item.allCities)"
          color="teal"
          size="26"
          rounded
        >
          <router-link
            :to="{
              name: 'BannerInstanceEdit',
              params: { bannerInstanceId: item.id }
            }"
          >
            <v-icon dark>mdi-pencil</v-icon>
          </router-link>
        </v-avatar>
      </template>
      <template v-slot:item.add="{ item }">
        <v-avatar
          v-if="currentUser.superAdmin || (!currentUser.superAdmin && !item.allCities)"
          color="red darken-4"
          size="26"
          rounded
        >
          <v-icon dark @click="delRecord(item)">mdi-delete</v-icon>
        </v-avatar>
      </template>
      <template v-slot:body.prepend>
        <tr>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-avatar color="indigo" size="30">
              <v-icon
                dark
                @click="
                  page = 1;
                  retrieveBannerInstances();
                "
                >mdi-magnify
              </v-icon>
            </v-avatar>
          </td>
          <td>
            <v-avatar color="grey" size="30">
              <v-icon
                dark
                @click="
                  page = 1;
                  clearSearch();
                "
                >mdi-close
              </v-icon>
            </v-avatar>
          </td>
          <td>
            <router-link :to="{ name: 'BannerInstanceCreate' }">
              <v-avatar color="teal" size="26" rounded>
                <v-icon dark>mdi-plus</v-icon>
              </v-avatar>
            </router-link>
          </td>
        </tr>
      </template>
    </v-data-table>
    <ConfirmDialog ref="confirm" />
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import CountryService from "@/core/services/country.service";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import moment from "moment";
import ConfirmDialog from "@/view/pages/ConfirmDialog";
import { mapGetters } from "vuex";

export default {
  name: "banner-instances",
  computed: {
    ...mapGetters(["currentUser"]),
    name() {
      return this.$i18n.t("menu.bannerInstances");
    },
    headers() {
      return [
        { text: "Id", align: "start", sortable: true, value: "id" },
        { text: "Banner", value: "bannerName" },
        { text: this.$i18n.t("common.start"), value: "start" },
        { text: this.$i18n.t("common.end"), value: "end" },
        { text: this.$i18n.t("commonTable.city"), value: "city" },
        { text: "Country", value: "country" },
        { text: this.$i18n.t("pages.recommendedCategories.allCities"), value: "allCities" },
        { text: this.$i18n.t("pages.sectors.active"), value: "active" },
        { text: "", value: "search", width: "5%" },
        { text: "", value: "clear", width: "5%" },
        { text: "", value: "add", width: "5%" }
      ];
    }
  },
  data() {
    return {
      totalBannerInstances: 0,
      bannerInstances: [],
      loading: true,
      pagination: {},
      showEditBannerInstanceDialog: false,
      dialogDelete: false,
      itemToDelete: null,
      errors: [],
      countries: [],
      selectedCountry: null
    };
  },
  watch: {
    pagination: {
      handler() {
        this.retrieveBannerInstances();
      },
      deep: true
    }
  },
  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.name }]);
    this.getCountries();
  },
  components: {
    ConfirmDialog
  },
  methods: {
    getCountries() {
      this.loading = true;
      CountryService.getCountries()
        .then(res => {
          if (!res?.length) return;
          this.countries = res;
          const activeCountryId = CountryService.getActiveCountry();
          this.selectedCountry = this.countries.find(item => item.id === activeCountryId);
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    },
    onChangeCountry() {
      this.retrieveBannerInstances();
    },
    changeBannerStatus(id, active) {
      this.loading = true;
      ApiService.patch(`api/banner-instance/${id}`, {
        active: active
      })
        .then(response => {
          this.$log.debug("active: ", response.data.active);
          this.retrieveBannerInstances();
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    },
    async delRecord(item) {
      if (
        await this.$refs.confirm.open(
          this.$i18n.t("common.confirm"),
          this.$i18n.t("pages.bannerDefinitions.instanceDelete") + item.id + " ?"
        )
      ) {
        this.deleteRecord(item);
      }
    },
    deleteRecord(item) {
      this.deleteBannerInstance(item.id);
    },
    parseDate(date) {
      return moment
        .utc(date)
        .local()
        .format("yyyy-MM-DD HH:mm:ss");
    },
    deleteItem(item) {
      this.itemToDelete = item;
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.deleteBannerInstance(this.itemToDelete.id);
      this.dialogDelete = false;
    },
    deleteBannerInstance(bannerInstanceId) {
      this.loading = true;
      ApiService.delete(`api/banner-instance/${bannerInstanceId}`)
        .then(response => {
          this.$log.debug("Banner instance deleted: ", response);
          this.retrieveBannerInstances();
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    },
    getRequestParams() {
      let params = {};

      if (this.selectedCountry) {
        params["countryId"] = this.selectedCountry.id;
      }

      let sort;
      let sortBy = this.pagination.sortBy;
      let sortDesc = this.pagination.sortDesc;
      if (sortBy.length === 1 && sortDesc.length === 1) {
        sort = sortBy[0];
        if (sortDesc[0] === true) sort += ",desc";
      }
      params["sort"] = sort;
      params["page"] = this.pagination.page;
      params["size"] = this.pagination.itemsPerPage;

      return params;
    },
    retrieveBannerInstances() {
      const params = this.getRequestParams();
      return new Promise(resolve => {
        this.loading = true;
        ApiService.query("api/banner-instance", {
          params
        }).then(response => {
          // JSON responses are automatically parsed.
          this.$log.debug("Banner instance: ", response.data.content);
          this.bannerInstances = response.data.content;
          this.totalBannerInstances = response.data.totalElements;
          this.totalPages = response.data.totalPages;
          this.loading = false;
          let title = this.totalBannerInstances != null ? this.totalBannerInstances : 0;
          this.$store.dispatch(SET_BREADCRUMB, [{ title: `${this.name}: ` + title }]);
          resolve();
        });
      });
    }
  }
};
</script>

<style>
.v-data-table.v-data-table.v-data-table >>> td {
  font-size: 16px;
}

.v-icon.v-icon {
  font-size: 24px !important;
}

tbody tr:nth-of-type(even) {
  background-color: rgba(236, 237, 237);
}

tbody tr:nth-of-type(odd) {
  background-color: rgb(250, 250, 250);
}
</style>
